import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Hero } from '../components/General/Hero';
import { Intro } from '../components/ProfessionalServices/Intro';
import { ValueCards } from '../components/ProfessionalServices/ValueCards';
import { ProfServExternalContactForm } from '../components/ProfessionalServices/ProfServExternalContactForm';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	formCont: {
		padding: '20rem 0 7rem',
		[theme.breakpoints.down('md')]: {
			padding: '16rem 0 5rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '12rem 0 1rem 0',
		},
	},
}));

const ProfessionalServicesPage = ({ data, location }) => {
	const professionalServices = data.professionalServices.edges[0].node;
	const classes = useStyles();
	const {
		hero,
		metaDescription,
		metaTitle,
		_rawIntro,
		introImage,
		valueCards,
		valueTitle,
		contactForm,
		externalForm,
		_rawFormFooterBody,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
	} = professionalServices;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={hero} />
			<Intro intro={_rawIntro} introImage={introImage} />
			<ValueCards header={valueTitle} cards={valueCards} />

			<WaveDownSVG fill='#FFFFFF' />
			<div
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}
				className={classes.formCont}>
				<ProfServExternalContactForm
					formValues={externalForm}
					contactForm={contactForm}
					_rawFormFooterBody={_rawFormFooterBody}
					endPoint='https://workwave.my.salesforce-sites.com/web2case/services/apexrest/cases/create'
					//endPoint='https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8'
				/>
				<Form
					privacy
					modal={false}
					noForm={true}
					location={location}
					pardotUrl='https://go.route-manager.com/l/1042281/2023-10-17/65r932'
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
		</>
	);
};

export const query = graphql`
	query SupportQuery {
		professionalServices: allSanityProfessionalServices {
			edges {
				node {
					metaTitle
					metaDescription
					hero {
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						_rawContent
					}
					_rawIntro
					introImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					valueCards {
						_rawBlurb
						accentColor {
							hexValue
						}
						faIcon
						title
					}
					valueTitle
					contactForm {
						header
						privacyPolicy
					}
					externalForm {
						fieldLabel
						fieldValue
						textArea
						externalFormSelect {
							fieldLabel
							fieldValue
						}
					}
					_rawFormFooterBody
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						blurb
						ctaLink
						ctaText
					}
				}
			}
		}
	}
`;

export default ProfessionalServicesPage;
