import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import ReCAPTCHA from 'react-google-recaptcha';
import PortableText from 'react-portable-text';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box, Button, Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import FormModalContext from '../../context/FormModalContext';

import '../ContactForm/marketo-styles/styles.css';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTextField-root': {
			margin: theme.spacing(1),
		},
	},
	contact: {
		paddingBottom: '4rem',
		// marginTop: '6rem',
	},
	header: {
		color: theme.white,
		fontWeight: 600,
		textAlign: 'center',
		fontSize: '2.1rem',
		[theme.breakpoints.down('md')]: {
			lineHeight: 1.2,
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '1.5rem',
		},
	},
	subText: {
		color: theme.workwaveBlue,
		fontSize: '20px',
		textAlign: 'center',
	},
	formContainer: {
		background: theme.white,
		//border: `1px solid ${theme.lightGray}`,
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		borderRadius: '20px',
		//marginTop: '2rem',
		//padding: '16px',
		width: '100%',

		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '100%',
		// 	width: 'auto',
		// 	margin: '0 16px',
		// },
	},
	form: {
		maxHeight: 'auto',
		overflow: 'hidden',
		padding: '0 24px !important',
		// [theme.breakpoints.down('lg')]: {
		// 	maxHeight: '465px',
		// },
		[theme.breakpoints.down('md')]: {
			// maxHeight: '840px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '0 10px',
		},
	},
	profServFormRow: {
		'&:nth-child(even)': {
			paddingLeft: '8px',
			paddingRight: '0px !important',

			[theme.breakpoints.down(767)]: {
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
			},
		},
		'&:nth-child(odd)': {
			paddingRight: '8px',
			paddingLeft: '0px !important',

			[theme.breakpoints.down(767)]: {
				paddingLeft: '0px !important',
				paddingRight: '0px !important',
			},
		},
	},
	profServFormRowFull: {
		paddingLeft: '0px !important',
		paddingRight: '0px !important',
	},
	formFooterText: {
		color: 'white',
		textAlign: 'center',
		fontSize: '2.1rem',
		marginTop: '2rem',
	},
}));

export const ProfServExternalContactForm = ({
	formValues,
	modal,
	contactForm,
	endPoint,
	ctaText = 'SUBMIT',
	_rawFormFooterBody,
}) => {
	const classes = useStyles();
	const [fields, setFields] = useState(null);
	const [loading, setLoading] = useState(true);
	const [token, setToken] = useState(null);
	const [missingFields, setMissingFields] = useState(false);
	const [error, setError] = useState(false);
	const [responseLoading, setResponseLoading] = useState(false);

	const { header, privacyPolicy } = contactForm;

	// //stuff for modal and form
	const { formModalOpen, handleModalClick, setFormModalOpen, email } =
		useContext(FormModalContext);

	let initialState = {};

	useEffect(() => {
		//iterate over the fields array from sanity, set state to [field]: null

		for (let field of formValues) {
			initialState = {
				...initialState,
				[field.fieldValue]: null,
			};
		}

		//like the submit function below, eventually we'll have to gate for request specific functionality
		setFields({
			...initialState,
			//orgid: '00D3000000003Kn',
			// captcha_settings: {
			// 	//keyname: 'GPSHeroesSupport',
			//   keyname: 'RouteManager',
			// 	fallback: true,
			// 	//orgId: '00D3000000003Kn',
			//   orgid: '00D4C0000004cAZ',
			// 	ts: '',
			// },
			//debug: '1',
			ownerid: '00G3A000004aQbuUAE',
			recordTypeId: '012300000019Tu3', //null,
			reason: 'Professional Services Request',
			subject: 'Professional Services Customer Request Web Submission',
			Software_Program__c: 'WorkWave RouteManager',
			Origin: 'Web',
			//recordTypeId: '012300000019SPv',
		});

		setLoading(false);
	}, [formValues]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFields({
			...fields,
			[name]: value,
		});

		// if (name === 'Software_Program__c') {
		// 	const recordTypeId = {
		// 		PestPac: '012300000019SPv',
		// 		'PestPac Lite': '012300000019SPv',
		// 		'WorkWave RouteManager': '012300000019SPv',
		// 		'WorkWave Service': '0123A000001DTpb',
		// 		'WorkWave GPS': '0123A0000019W1D',
		// 		'Marketing Services': '0123A0000019Vsj',
		// 	}[value];
		// 	setFields({
		// 		...fields,
		// 		[name]: value,
		// 		recordTypeId,
		// 	});
		// }
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const requiredMissing = Object.values(fields).some((field) => {
			if (field === null) {
				return true;
			}
		});

		if (requiredMissing) {
			setMissingFields(true);
			return;
		}

		try {
			setResponseLoading(true);

			setMissingFields(false);
			setError(false);
			const response = await axios.post(endPoint, fields, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': true,
					'Content-Type': 'application/json',
				},
			});
			navigate('/professional-services/thank-you');
		} catch (err) {
			setError(err.response.data);
		} finally {
			resetForm();
			setResponseLoading(false);
		}
	};

	const resetForm = () => {
		for (let field of formValues) {
			initialState = {
				...initialState,
				[field.fieldValue]: null,
			};
		}
		setFields({
			...initialState,
			//orgid: '00D3000000003Kn',
			//debug: '1',
			ownerid: '00G3A000004aQbuUAE',
			recordTypeId: '012300000019Tu3', //null,
			reason: 'Professional Services Request',
			subject: 'Professional Services Customer Request Web Submission',
			Software_Program__c: 'WorkWave RouteManager',
			Origin: 'Web',
		});
	};

	const onChange = (value) => {
		setToken(value);
	};

	return (
		<Container>
			<div
				className={classes.contact}
				style={{
					padding: modal ? '1rem' : 0,
					marginBottom: '12rem', //!modal ? '2rem' : null,
				}}>
				<Grid container direction='column' justify='center' alignItems='center'>
					{/* <Grid item className={classes.subText}>
            <Typography variant='body1' className={classes.subText}>
              {body}
            </Typography>
          </Grid> */}
					<Box className={classes.formContainer}>
						<div
							style={{
								background: '#055291',
								borderRadius: '16px 16px 0 0',
								padding: '.5rem 0',
								width: '100%',
								marginBottom: '1.5rem',
							}}>
							{header && (
								<Grid container justifyContent='center'>
									<Grid item xs={9} sm={10} md={11}>
										<Typography variant='h2' className={classes.header}>
											{header}
										</Typography>
									</Grid>
								</Grid>
							)}
						</div>
						<Grid container>
							{loading ? (
								<CircularProgress />
							) : (
								<form
									onSubmit={(e) => handleSubmit(e)}
									className={`mktoForm mktoHasWidth mktoLayoutLeft ${classes.form}`}>
									{/* iterate over the fields and set the value to the state value and the name to the sanity field name */}
									{formValues
										.filter((field) => field.fieldLabel)
										.map((f, index) => {
											return (
												<Grid
													item
													md={
														f.fieldValue === 'Subject' ||
														f.fieldValue === 'Description'
															? 12
															: 6
													}
													key={index}
													className={
														f.fieldValue === 'Subject' ||
														f.fieldValue === 'Description'
															? `mktoFormRow fullWidth ${classes.profServFormRowFull}`
															: `mktoFormRow ${classes.profServFormRow}`
													}>
													<div
														className='mktoFieldDescriptor mktoFormControl'
														style={{ marginBottom: '10px' }}>
														<div className='mktoFieldWrap mktoRequiredField'>
															<label
																for={f.fieldValue}
																className='mktoLabel mktoHasWidth'>
																<span
																	class='mktoAsterix'
																	style={{ color: '#BF0021' }}>
																	*
																</span>
																{f.fieldLabel}
															</label>
															{f.externalFormSelect.length ? (
																<select
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}>
																	{f.externalFormSelect.map((select, index) => (
																		<option
																			key={index}
																			value={select.fieldValue}>
																			{select.fieldLabel}
																		</option>
																	))}
																</select>
															) : f.textArea ? (
																<textarea
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}
																	rows='5'
																	required
																	className='mktoField mktoTextField mktoHasWidth mktoRequired'
																	placeholder=''
																	class='form-control'
																/>
															) : (
																<input
																	onChange={(e) => handleChange(e)}
																	value={fields[f.fieldValue]}
																	name={f.fieldValue}
																	required
																	className='mktoField mktoTextField mktoHasWidth mktoRequired'
																	placeholder=''
																	class='form-control'
																/>
															)}
														</div>
														<div className='mktoClear' />
													</div>
												</Grid>
											);
										})}
									{/* render error message for missing fields or server error */}
									<Grid container direction='row' style={{ marginLeft: '' }}>
										<ReCAPTCHA
											sitekey='6LeqdFEfAAAAAEoQ9SVfaW6EAFzSF-mrV3gWCTtj'
											onChange={onChange}
										/>
									</Grid>

									{missingFields && (
										<Grid container direction='row' justify='center'>
											{' '}
											<Typography
												variant='body1'
												style={{
													color: '#BF0021',
													textAlign: 'center',
													marginTop: '1rem',
												}}>
												Please ensure you have included all required fields.
											</Typography>
										</Grid>
									)}
									{error && (
										<Grid container direction='row' justify='center'>
											{error}
										</Grid>
									)}
									<div
										style={{ clear: 'both' }}
										className='g-recaptcha'
										data-sitekey='6LeqdFEfAAAAAEoQ9SVfaW6EAFzSF-mrV3gWCTtj'></div>
									<Grid
										container
										direction='row'
										justify='center'
										className='mktoButtonRow'>
										{responseLoading ? (
											<CircularProgress />
										) : (
											<span className='mktoButtonWrap mktoSimple'>
												<Button
													color='primary'
													size='large'
													variant='contained'
													className='mktoButton'
													style={{
														marginTop: missingFields || error ? 'none' : '1rem',
													}}
													onClick={(e) => handleSubmit(e)}>
													{ctaText}
												</Button>
											</span>
										)}
									</Grid>
									<Typography
										variant='body1'
										style={{
											marginTop: '2rem',
											marginBottom: '1rem',
											color: '#8394A2',
										}}>
										{privacyPolicy}
									</Typography>
								</form>
							)}
						</Grid>
					</Box>
					<Grid item xs={12} md={8}>
						<Typography variant='body1' className={classes.formFooterText}>
							<PortableText
								content={_rawFormFooterBody}
								serializers={{
									normal: ({ children }) => <span>{children}</span>,
									strong: ({ children }) => (
										<span
											style={{
												textDecoration: 'underline',
												color: '#ffffff',
												fontWeight: 600,
												cursor: 'pointer',
											}}
											onClick={setFormModalOpen}>
											{children}
										</span>
									),
								}}
							/>
						</Typography>
					</Grid>
				</Grid>
			</div>
		</Container>
	);
};
